:root {
  /* used in inst-ui theme (./theme.js) */
  --backgroundLight: whitesmoke;
  --brand: #31748b;
  --link: #31748b;
  --themeBorderMedium: #c7cdd1; /* matches borderMedium in canvas theme */
  /* used in this CSS only */
  --brandText: white;
  /* "crimson" branding matches "theme-color" in index.html head meta tags;
    color taken from https://guidelines.hwp.harvard.edu/color-palette */
  --crimson: #a51c30;
  --tableHeaderMuted: grey;
}

/* Inst-UI's theming appears to cause "flickering" when components are customized
  at runtime; for this reason, and because not all aspects of component display
  are themable, we override some component and color and behaviors directly in the CSS
  (though we rely on our custom theme.js as much as we can to achieve e.g. color
  consistency) */

/* GLOBAL ===================================================== */

/* using <Link> from @instructure/ui-link we may not need this, <Link> from
  react-router does necessitate styling outside of the theming */
a,
a:visited {
  color: var(--link);
}
a:focus {
  outline-color: var(--link);
}

/* Show cursor: pointer on unselected rows in content tables (results list,
  term list) to provide hint to user that the rows are clickable. Do not show
  pointer for selected rows so that default cursor (e.g. vertical text selection
  caret) can indicate capability to do other types of interactions such as text
  selection (as clicking on a selected row does not produce any additional response
  from the application).
*/
tbody tr:not([data-selected='true']) {
  cursor: pointer;
}

tr {
  /* override user agent default <tr> focus outline */
  outline-color: var(--brand);
}
/* Align relevance graphic to top of row;
  Note that @instructure/ui-table does not support adding
  class names to <td> or <td> elements, so we use data-*
  fields instead.
*/
tr[data-selected='true'] > td[data-field='relevance'] {
  vertical-align: top;
}

/* inline filter list: e.g. <ul><li>1</li><li>2</li><li>3</li></ul> ==> 1, 2, 3 */
ul.filter-list {
  display: inline;
  list-style: none;
  padding: 0px;
}
ul.filter-list li {
  display: inline;
}
ul.filter-list li::after {
  content: ', ';
}
ul.filter-list li:last-child::after {
  content: '';
}

/* Show `cursor: pointer` on select inputs in query filter to provide hint to
  user that the inputs are clickable. Note that `!important` is used to override
  a `cursor: auto` rule of higher specifity generated by the instructure/ui Select
  component (targeting an input with a specific class)
*/
.select-input-container input,
.select-input-container label {
  cursor: pointer !important;
}

/* SPECIFIC COMPONENTS  ===================================================== */

.no-results-msg {
  margin-top: 1rem;
}

/* When the term for the course details section has no instructor or my_h_id information,
  ensure there is still spacing between the "Details" heading (#course-detail-term-heading)
  and the instructor heading (#course-detail-instructor-heading, if present) and/or download
  button (contained by the #download-syllabus-form).
*/
#course-detail-term-heading + #course-detail-instructor-heading,
#course-detail-term-heading + #download-syllabus-form {
  padding-top: 1rem;
}

/* provide a little spacing between form button and the text following */
#department-details-view-related-courses-button {
  margin-top: 0rem !important; /* `!important` overrides element-level styling */
  margin-bottom: 1rem !important; /* `!important` overrides element-level styling */
}

#detail-view h4 {
  color: var(--crimson);
}
#detail-view-back-to-results-button {
  padding-bottom: 1rem;
}
#detail-view-course-title {
  padding: 1rem 0;
}
/* Override default (and unconfigurable) inst-ui table styles
  for course detail term list/table */
#detail-view table {
  margin-bottom: 1.5rem;
}
#detail-view th {
  font-size: smaller;
  color: var(--tableHeaderMuted);
}
#detail-view td,
#detail-view th {
  background-color: var(--backgroundLight);
}
#detail-view td span {
  /* ensure table cell text lines up with table header text */
  padding: 0 !important; /* `!important` overrides element-level styling */
}
#detail-view tr {
  border: none;
}

#error-boundary {
  padding: 0.75rem;
}

#feedback-modal p {
  margin-top: 0;
}
#feedback-modal label {
  margin-top: 2rem;
}

#footer {
  min-height: 3rem;
}
#footer a {
  text-decoration: none;
}

.help-pages-container {
  margin-left: 1rem;
  margin-top: 1rem;
  margin-right: 1rem;
}
.help-pages-container div {
  margin-left: 1rem;
  margin-top: 1rem;
}
.help-pages-container div:last-of-type {
  margin-bottom: 1rem;
}

#main-search-box div[role='tabpanel'] div {
  border-bottom: 1px solid var(--themeBorderMedium);
  border-left: 1px solid var(--themeBorderMedium);
  border-right: 1px solid var(--themeBorderMedium);
}
#main-search-box div[role='tab'][aria-selected='true'] {
  border-bottom-color: var(--backgroundLight);
}
#main-search-box div[role='tab'][aria-selected='true'],
#main-search-box div[role='tabpanel'] div {
  background-color: var(--backgroundLight);
}

#results a {
  text-decoration: none;
}
/* ensure adequate padding for sections, and negative space for
    focus outline to be visible all the way around input elements
    e.g. buttons */
#collapsed-query-details-container,
#no-results-message-container,
#results-count-container,
#results-heading,
#return-from-results {
  padding: 1rem;
}
#results-heading h4 {
  color: var(--crimson);
}

#search-bar a {
  text-decoration: none;
}
#search-bar label span {
  font-weight: normal;
}

/* style selected results, search panel, and details panel with similar
  light background per UX mockups */
#search-bar,
#search-bar div span, /* this is needed to override some component-level styles */
#detail-view[data-active='true'],
tr[data-selected='true'] > td {
  background-color: var(--backgroundLight);
}

#top-bar {
  background-color: var(--crimson);
  width: 100%;
}
#top-bar-title {
  /* Ensure text and icon in top bar are light-on-dark */
  color: var(--brandText);
  text-decoration: none;
}
#top-bar a:active,
#top-bar a:focus {
  outline-color: white;
  outline-offset: 3px;
}
#top-bar h2 {
  font-size: 1.25rem;
  font-weight: bold;
}

#welcome-container h1 {
  color: var(--crimson);
}

#welcome-help-links a,
#welcome-help-links button {
  text-decoration: none;
}

.toggle {
  text-decoration: none;
}

/* SPECIFIC FUNCTIONALITY  ===================================================== */

/* START: fill viewport settings */

/* to have long columns of data in the <main> section overflow and keep
    the footer at the bottom of the page, and ensure the footer is also
    pushed to the bottom of the page if the data columns are shorter than
    the available space, we need to ensure that those sections housed in
    <main> use 100% of the height of their containers, and that the parent
    containers all the way up to the <html> node use 100% of the available
    viewport height. Many of the height settings are defined inline
    (e.g. for <Flex> components), but the ones that cannot be defined inline
    are handled here.
*/

html,
body,
#root,
/* this main > div is created by the <Responsive> component;
    it cannot be given an `id` or `className` when rendering, so
    we have to target it like so (or we could use styled components)
*/
main > div,
/* these two div children of #query-tray are created by the <Tray> component;
    they cannot be given an `id` or `className` when rendering, so
    we have to target them like so (or we could use styled components)
*/
#query-tray div[role='dialog'],
#query-tray div[class$='tray__content'] {
  height: 100%;
}

/* END: fill viewport settings */
